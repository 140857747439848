<template>
  <section class="relative">
    <div class="container">
      <div class="relative overflow-visible rounded-3xl">
        <div class="relative z-10 flex flex-col items-center pt-12 text-center">
          <Badge
            label="Happy Customers"
            background-color="bg-green-50"
            text-color="text-green-600"
            border-color="border-green-600" />
          <h2
            class="mt-6 max-w-screen-lg text-4xl font-bold uppercase text-green-500 md:text-5xl lg:text-6xl">
            Move or Remove Anything that fits into a van, ute or truck.
          </h2>

          <div class="flex flex-wrap justify-center py-12">
            <BlockHighlight
              value="15,000+"
              label="Happy customers">
              <Icon
                name="IconCustomers"
                class="text-7xl" />
            </BlockHighlight>
            <BlockHighlight
              value="10,000+"
              label="Deliveries completed">
              <Icon
                name="IconTruck"
                class="text-7xl" />
            </BlockHighlight>
            <BlockHighlight
              value="3,000+"
              label="Loads junk removed">
              <Icon
                name="IconRecycle"
                class="text-7xl" />
            </BlockHighlight>
            <BlockHighlight
              value="100+"
              label="Retail partners">
              <Icon
                name="IconStore"
                class="text-7xl" />
            </BlockHighlight>
            <BlockHighlight
              value="60s"
              label="Booking time">
              <Icon
                name="IconClock"
                class="text-7xl" />
            </BlockHighlight>
          </div>
        </div>
        <!-- Spacer -->
        <!-- <div class="h-[300px]" /> -->
      </div>
    </div>
    <BlockMarquee
      :testimonials="testimonials"
      class="pb-16" />
    <NuxtImg
      src="images/map.jpg"
      loading="lazy"
      sizes="100vw md:800px lg:1200px"
      class="absolute left-0 top-0 size-full rounded-3xl object-cover object-center opacity-30 max-md:hidden max-md:bg-gray-50"
      alt="Map of Brisbane" />
  </section>
</template>

<script setup lang="ts">
const client = useSupabaseClient()

// Fetch Testimonials
const { data } = await useLazyAsyncData('testimonials', async () => {
  const { data, error } = await client.from('testimonials').select('name, content').limit(100)
  if (error) throw error
  return data
})

const testimonials = computed(() => {
  // Randomly select up to 20
  if (data.value) {
    const shuffled = [...data.value].sort(() => Math.random() - 0.5).slice(0, 20)
    return shuffled
  }

  return []
})
</script>
